import React, { useState } from "react";
import Alert from "../../../../components/share/Alert";
import TextField from "../../../../components/share/fields/TextField";
import SubmitButtons from "../../../../components/share/fields/SubmitButtons";
import { deleteAccount } from "../../../../api/apiGlobal";

const DeleteAccount = () => {

  const [accountId, setAccountId] = useState("");
  const [confirm, setConfirm] = useState("");

  const [processing, setProcessing] = useState(false);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  // Todo make sure we can't acess without beoing logged in
  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <p>Please make sure there are no more profiles</p>
              <TextField
                handleChange={(e) => {
                  setAccountId(e.target.value);
                }}
                id="account-id"
                label="Account Id"
                placeholder="e.g. xxxxx"
                text={accountId}
                helpText="Account id."
              ></TextField>

              <TextField
                handleChange={(e) => {
                  console.log("confirm", e.target.value);
                  setConfirm(e.target.value);
                }}
                id="confirm"
                label="Confirm"
                placeholder="Type `delete` to confirm"
                text={confirm}
                helpText="Type `delete` to confirm"
              ></TextField>

              <SubmitButtons
                processing={processing}
                backButtonTo="/"
                handleClick={async (e) => {
                    e.preventDefault();
                    if (confirm !== "delete") {
                      setAlert({
                        show: true,
                        type: "danger",
                        message: "Please type `delete` to confirm",
                        count: alert.count + 1,
                      });
                      return;
                    }
                    setProcessing(true);

                    try {
                      const response = await deleteAccount(accountId);
                      setAlert({
                        show: true,
                        type: "info",
                        message: `Account ${response.accountId} (${response.accountName}) was deleted successfully`,
                        count: alert.count + 1,
                      });
                      // Clear form
                      setAccountId("");
                      setConfirm("");
                    } catch (error) {
                      const errMsg = error?.response?.data?.message || "Oops, something went wrong. Please try again later.";
                      setAlert({
                        show: true,
                        type: "danger",
                        message: errMsg,
                        count: alert.count + 1,
                      });
                    } finally {
                      setProcessing(false);
                    }
                  }}
                ></SubmitButtons>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default DeleteAccount;

import React from "react";
import { Button, Modal } from "../../../../../../components/share/InsightechUI";
import { HiOutlineExternalLink } from "react-icons/hi";

const ExtensionModal = (props) => {
  const { isPopupVisible, handleClosePopup, clickedRowPageUrl } = props;
  return (
    <Modal
      isVisible={isPopupVisible}
      handleClose={handleClosePopup}
      footer={
        <div className="modal-btn-group">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              window.open(clickedRowPageUrl, "_blank");
            }}
          >
            <HiOutlineExternalLink size={18} />
            Open Page
          </Button>
        </div>
      }
    >
      <p>
        <b>To Analyze the Live Page with the Insightech Chrome Extension:</b>
      </p>
      <p>
        <b>Step 1:</b> If needed, install the
        <a
          target="_blank"
          href="https://chrome.google.com/webstore/detail/insightech-extension/fojjmhaiegijdpkhiliecbmaeblogcld"
        >
          <b> Insightech Chrome Extension </b>
        </a>
        from the Chrome Web Store.
      </p>
      <p>
        <b>Step 2:</b> Click the <b>Open Page</b> button to open the live page
        in a new tab.
      </p>
      <p>
        <b>Step 3:</b> On the live page, open <b>DevTools</b> by pressing{" "}
        <b>F12</b> or
        <b> right-clicking</b> and selecting <b>Inspect</b>.
      </p>
      <p>
        <b>Step 4:</b> In the <b>DevTools</b> panel, go to the <b>Insightech</b>{" "}
        tab to start analyzing the page.
      </p>
    </Modal>
  );
};

export default ExtensionModal;

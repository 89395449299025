import React from "react";
import PropTypes from "prop-types";
import InsightechIcon from "../InsightechIcon";
import "./style.scss";

const SearchBar = ({ searchInputText, handleKeywordChange, placeholder }) => {
  return (
    <div className="search-section">
      <InsightechIcon name="Search" />
      <input
        type="search"
        className="form-control form-control-sm"
        placeholder={placeholder}
        value={searchInputText}
        onChange={handleKeywordChange}
      ></input>
    </div>
  );
};

SearchBar.propTypes = {
  searchInputText: PropTypes.string,
  handleKeywordChange: PropTypes.func,
};

export default SearchBar;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Input, Switch } from "./../../share/InsightUI";
import SubCondition from "./SubCondition";
import { conditionFixture } from "./../../../fixtures/filters";
import { deepCopy } from "./../../../utils";
import InsightechIcon from "../../share/InsightechIcon";
import * as Icons from "../../assets/icons/MenuIconProvider";
import SelectorDropdown from "./components/SelectorDropdown";

const defaultConditions = require("./../../../inc/eventConditions");
const operators = require("./../../../inc/operators");
const units = require("./../../../inc/units");
const operatorConditions = require("./../../../inc/operatorConditions.json");

function EventCondition(props) {
  const {
    invalidFields,
    containerIndex,
    eventIndex,
    isFunnel,
    data,
    filters,
    conditionType,
    showDelete,
    customConditions,
    handleUpdateCondition,
    handleUpdateSubCondition,
    handleClickDeleteConditionRow,
    handleClickAddSubConditionRow,
    handleClickDeleteSubConditionRow,
    handleSaveConversionPoint,
    editFieldOnly,
    enableRevenue,
    onEnableRevenue,
    revenueJsonPath,
    onRevenueJsonPath,
    isConversion,
    isFormProcessing,
    enabled,
    onEnabled,
  } = props;

  const valueIndex = 0;
  const conditions = customConditions ? customConditions : defaultConditions;
  const { attribute, path, unit, operator, values } = data.conditions[0];
  const [availableOperators, setAvailableOperators] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dropdownRef = useRef(null);
  const triggerButtonRef = useRef(null);

  const operatorType = conditions[attribute]
    ? conditions[attribute].operatorType
    : null;
  const availableUnits = units[operatorType] ? units[operatorType] : null;
  const availableConditionsKeys =
    filters[data.type] && filters[data.type]
      ? filters[data.type].attributes
      : null;
  const operatorCondition =
    operator && operatorConditions[operator]
      ? operatorConditions[operator]
      : null;

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
        setSearchText("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    let conditionKey =
      conditions[attribute] && conditions[attribute]["operatorType"]
        ? conditions[attribute]["operatorType"]
        : null;
    let conditionOperators = conditionKey ? operators[conditionKey] : null;
    if (conditionOperators) {
      setAvailableOperators(conditionOperators);
    } else {
      setAvailableOperators([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);

  const onClickDeleteConditionRow = (e) => {
    handleClickDeleteConditionRow(
      conditionType,
      props.containerIndex,
      props.eventIndex
    );
  };

  const handleEventTypeChange = (eventType) => {
    const state = {
      type: eventType,
      conditions: [deepCopy(conditionFixture)],
    };

    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      0, // conditionIndex is always 0 for event type changes
      state
    );
  };

  const onClickAddSubConditionRow = (e) => {
    handleClickAddSubConditionRow(
      conditionType,
      props.containerIndex,
      props.eventIndex
    );
  };

  const handleChangeAttribute = (e) => {
    const state = deepCopy(data);

    // Reset condition if attribute is changed
    state["conditions"] = [deepCopy(conditionFixture)];
    state["conditions"][0].attribute = e.target.value;
    if (e.target.value === "FieldSelected") {
      state["conditions"][0]["values"] = ["true"];
    }
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleChangeOperator = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["operator"] = e.target.value;
    if (operatorType && units[operatorType]) {
      state["conditions"][0]["unit"] = units[operatorType][0].key;
    }
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleChangeValue = (e) => {
    const state = deepCopy(data);
    const index = e.target.dataset.index;
    state["conditions"][0]["values"][index] = e.target.value;
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleChangePath = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["path"] = e.target.value;
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleChangeUnit = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["unit"] = e.target.value;
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleClickAddValueField = (e) => {
    const state = deepCopy(data);
    state["conditions"][0]["values"].push("");
    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const handleClickRemoveValueField = (e) => {
    const state = deepCopy(data);
    const index = parseInt(e.currentTarget.dataset.index, 0);
    if (index === 0) {
      state.conditions[0].values[0] = "";
    }
    if (state.conditions[0].values.length > 1) {
      state.conditions[0].values.splice(index, 1);
    }

    handleUpdateCondition(
      conditionType,
      props.containerIndex,
      props.eventIndex,
      e.currentTarget.dataset.conditionindex,
      state
    );
  };

  const invalidFieldKey =
    conditionType === "event"
      ? "includedPageContainers"
      : "excludedPageContainers";

  const getInvalidClass = (
    invalidFieldKey,
    containerIndex,
    valueIndex,
    type
  ) => {
    return (
      invalidFields &&
      invalidFields[invalidFieldKey] &&
      invalidFields[invalidFieldKey][containerIndex] &&
      invalidFields[invalidFieldKey][containerIndex]["eventConditions"] &&
      invalidFields[invalidFieldKey][containerIndex]["eventConditions"][
        eventIndex
      ] &&
      invalidFields[invalidFieldKey][containerIndex]["eventConditions"][
        eventIndex
      ].conditions[0][type === "value" ? `value${valueIndex}` : type] &&
      "form-control-error"
    );
  };

  const renderIcon = (iconName) => {
    const IconComponent = Icons[iconName]; // Resolve the icon dynamically
    return IconComponent ? <IconComponent /> : null; // Render the component if it exists
  };

  return (
    <div className={`rule ${isFunnel ? "funnel-mode" : ""}`}>
      <div className="rule-group">
        {/** Event Type Field */}
        <div className="field-col" ref={dropdownRef}>
          <div className={`attribute-selector`}>
            <div
              ref={triggerButtonRef}
              className="selected-attribute"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {data.type ? (
                <div className="label-group">
                  {filters[data.type]?.key &&
                    renderIcon(filters[data.type].key)}
                  {filters[data.type]?.label || "Choose an event"}
                </div>
              ) : (
                <span className="placeholder">Choose an event</span>
              )}
              <InsightechIcon
                name={isDropdownOpen ? "ChevronUp" : "ChevronDown"}
              />
            </div>

            {isDropdownOpen && (
              <SelectorDropdown
                menuType="event"
                searchText={searchText}
                searchPlaceholder="Search an event"
                selectedData={data.type}
                filters={filters}
                handleEventTypeChange={handleEventTypeChange}
                setIsDropdownOpen={setIsDropdownOpen}
                setSearchText={setSearchText}
                renderIcon={renderIcon}
                triggerRef={triggerButtonRef}
              />
            )}
          </div>
        </div>

        {/** Condition Field */}
        {data.type && (
          <div className="field-col">
            <select
              data-conditionindex={0}
              className={`form-control form-control-sm ${getInvalidClass(
                invalidFieldKey,
                containerIndex,
                valueIndex,
                "attribute"
              )}`}
              name="condition"
              value={attribute}
              onChange={handleChangeAttribute}
            >
              <option value="">Choose a Condition</option>
              {availableConditionsKeys.map((key) => {
                const condition = conditions[key];
                return (
                  <option key={condition.key} value={condition.key}>
                    {condition.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {/** JSON Path or Api Error JSON Path Payload */}
        {data.type &&
          attribute &&
          (attribute === "JSONPath" ||
            attribute === "ApiErrorJsonPathPayload") && (
            <div className="field-col">
              <input
                data-conditionindex={0}
                placeholder="Define JSON Path"
                type="text"
                className="form-control form-control-sm"
                name="value"
                value={path}
                onChange={handleChangePath}
              />
            </div>
          )}

        {/** Operator Field */}
        {!!(
          (data.type &&
            attribute &&
            (attribute !== "JSONPath" ||
              attribute !== "ApiErrorJsonPathPayload") &&
            availableOperators.length) ||
          (data.type &&
            attribute &&
            (attribute === "JSONPath" ||
              attribute === "ApiErrorJsonPathPayload") &&
            path &&
            availableOperators.length)
        ) && (
          <div className="field-col">
            <select
              data-conditionindex={0}
              className={`form-control form-control-sm ${getInvalidClass(
                invalidFieldKey,
                containerIndex,
                valueIndex,
                "operator"
              )}`}
              name="operator"
              value={operator}
              onChange={handleChangeOperator}
            >
              <option value="">Choose an operator</option>
              {availableOperators.map((op) => {
                return (
                  <option key={op.key} value={op.key}>
                    {op.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {/** Value Field */}
        {(!!(data.type && attribute && attribute !== "Any" && operator) ||
          !!(data.type && attribute && operatorType === "boolean")) &&
          (operatorType === "boolean" ? (
            <div className="field-col">
              <select
                data-conditionindex={0}
                value={values[0]}
                data-index={0}
                className="form-control form-control-sm"
                onChange={handleChangeValue}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          ) : (
            values.map((val, index) => {
              let inputType = "text";
              switch (operatorType) {
                case "number":
                case "duration":
                  inputType = "number";
                  break;
                default:
                  break;
              }
              return (
                <React.Fragment key={index}>
                  <div
                    className={`field-col value-field-container
                      ${availableUnits && "with-unit"}`}
                    key={index}
                  >
                    <span className="conj">or</span>
                    <input
                      data-conditionindex={0}
                      type={inputType}
                      className={`form-control form-control-sm ${getInvalidClass(
                        invalidFieldKey,
                        containerIndex,
                        index,
                        "value"
                      )}`}
                      name="value"
                      data-index={index}
                      value={val}
                      onChange={handleChangeValue}
                    />
                    {!availableUnits && (
                      <Button
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    )}
                  </div>

                  {/* Units Field */}
                  {availableUnits && (
                    <div className="field-col unit-field-container">
                      <select
                        data-conditionindex={0}
                        className="form-control form-control-sm"
                        value={unit}
                        onChange={handleChangeUnit}
                      >
                        {availableUnits.map((unit, index) => {
                          return (
                            <option key={index} value={unit.key}>
                              {unit.label}
                            </option>
                          );
                        })}
                      </select>
                      <Button
                        data-conditionindex={0}
                        className="btn-delete"
                        data-index={index}
                        size="small"
                        icon="fas fa-times"
                        onClick={handleClickRemoveValueField}
                      ></Button>
                    </div>
                  )}
                </React.Fragment>
              );
            })
          ))}

        {/** OR Button */}
        {data.type &&
          attribute &&
          attribute !== "Any" &&
          operator &&
          (!operatorCondition ||
            (operatorCondition && !operatorCondition.singleValue)) && (
            <div className="field-col add-btn-container field-col">
              <Button
                data-conditionindex={0}
                variant="secondary"
                size="small"
                onClick={handleClickAddValueField}
              >
                or
              </Button>
            </div>
          )}

        {/** Action Field */}
        {data.conditions.length === 1 && !editFieldOnly && (
          <div className="field-col add-condition-btn-container">
            {data.type && attribute && (
              <Button
                size="small"
                onClick={onClickAddSubConditionRow}
                variant="secondary"
              >
                and
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="actions">
        {showDelete && !editFieldOnly && (
          <Button
            data-containerindex={props.containerIndex}
            data-eventindex={eventIndex}
            variant="plain"
            icon="fas fa-times-circle"
            onClick={onClickDeleteConditionRow}
          ></Button>
        )}
      </div>

      {data.conditions && !!data.conditions.length && (
        <div className="">
          {data.conditions.map((condition, index) => {
            return index === 0 ? null : (
              <SubCondition
                invalidFields={invalidFields}
                editFieldOnly={editFieldOnly}
                containerIndex={props.containerIndex}
                eventIndex={props.eventIndex}
                conditionIndex={index}
                id={index}
                key={index}
                data={condition}
                customConditions={customConditions || null}
                conditionType={conditionType}
                type={data.type}
                eventId={props.eventIndex}
                handleUpdateSubCondition={handleUpdateSubCondition}
                handleClickAddSubConditionRow={handleClickAddSubConditionRow}
                handleClickDeleteSubConditionRow={
                  handleClickDeleteSubConditionRow
                }
              />
            );
          })}
        </div>
      )}

      {/**Conversion Field - Content Element Loaded */}
      {isConversion && data.conditions && data.type === "ContentLoad" && (
        <div className="field-col">
          <Input size="medium" type="checkbox" id="enableRevenue" />
          <label className="mr-2">
            Extract element text as conversion revenue
          </label>
        </div>
      )}

      {/**Conversion Field - data layer event */}
      {isConversion && data.conditions && data.type === "CustomData" && (
        <div className="field-col">
          <Input
            size="medium"
            type="checkbox"
            id="enableRevenue"
            checked={enableRevenue}
            onChange={onEnableRevenue}
          />
          <label className="mr-2">
            Extract data layer value from JSON path
          </label>
          <Input
            id="revenueJsonPath"
            value={revenueJsonPath}
            onChange={onRevenueJsonPath}
          />
          <label className="ml-2">as revenue </label>
        </div>
      )}

      {isConversion && (
        <>
          <div className="field-col">
            <label className="pt-2 mr-2">Disabled</label>
            <Switch
              id="enabled"
              value={enabled}
              isChecked={enabled}
              onChange={onEnabled}
            />
            <label className="pt-2 ">Enabled</label>
          </div>
          <div className="field-col">
            <Button
              onClick={handleSaveConversionPoint}
              title="Save Conversion Point"
              variant="primary"
              size="small"
              disabled={isFormProcessing}
            >
              Save Conversion Point
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

EventCondition.propTypes = {
  handleClickDeleteConditionRow: PropTypes.func,
};
EventCondition.defaultProps = {};

export default EventCondition;

import React from "react";
import Button from "../../../../share/InsightechUI/Button";
import { FaArrowCircleUp } from "react-icons/fa";
import { navigateToPlanUpgrade } from "../../../../../utils";

const UpgradeFlag = (props) => {
  const { id, isIconOnly, type, flagContent = "Upgrade" } = props;

  if (isIconOnly) {
    return (
      <span
        id={id}
        className="upgrade-flag"
        onClick={() => {
          navigateToPlanUpgrade();
        }}
      >
        <FaArrowCircleUp size={18} />
      </span>
    );
  }

  return (
    <Button
      id={id}
      size="small"
      color={type === "plain" ? "plain-orange" : "outlined-orange"}
      onClick={() => {
        navigateToPlanUpgrade();
      }}
    >
      <FaArrowCircleUp size={18} />
      {flagContent}
    </Button>
  );
};

export default UpgradeFlag;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config/config.json";

// Override console.warn to suppress specific warnings
const originalWarn = console.warn;

console.warn = function (...args) {
  const arg = args && args[0];

  if (arg && arg.includes("Attempting to load version '51' of Google Charts"))
    return;

  originalWarn(...args);
};

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [new Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\/api\//, /^\/api2\//], // Only send trace headers (baggage & sentry-trace) to whitelisted endpoints
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

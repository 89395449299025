import React, { useEffect, useState, useContext } from "react";
import { UserDataContext } from "../UserData";
import {
  kSeparatorDecimal,
  formatValueWithSymbol,
  calculatePercentage,
  calculateConversionRateDifference,
} from "../../../utils";
import Table from "./Table";
import ComparisonTable from "./ComparisonTable";
import EntryMatchComparisonTable from "./EntryMatchComparisonTable";
import { Button } from "../../share/InsightUI";
import "./style.scss";

const DashboardTable = (props) => {
  const {
    isCompare,
    isTrend,
    tableData,
    comparedTableData,
    widget,
    getDiffArrow,
  } = props;
  const {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    isDateCompareOn,
    isSegmentCompareOn,
  } = useContext(UserDataContext);
  const [data, setData] = useState();
  const [compareRemainingData, setCompareRemainingData] = useState();
  const [isViewAllData, setIsViewAllData] = useState(false);
  const isLazyDisplay = widget === "channel" || widget === "campaign";

  const handleViewAllData = () => {
    setIsViewAllData(!isViewAllData);
  };

  const generateDataObject = (row, headers) => {
    const dataObject = {};
    headers.forEach((header, index) => {
      dataObject[header] = row[index];
    });
    return dataObject;
  };

  const processComparedDataRows = (rowData, headers, comparedData) => {
    return headers.map((header) => {
      if (header === "Conversion Rate") {
        return calculateConversionRateDifference(
          rowData[header],
          comparedData[header]
        );
      } else if (typeof rowData[header] === "number") {
        return calculatePercentage(rowData[header], comparedData[header]);
      }
      return "";
    });
  };

  const processDataRowWithSymbols = (rowData, headers) => {
    return headers.map((header) =>
      formatValueWithSymbol(
        header === "Revenue" ? "$" : "",
        kSeparatorDecimal(rowData[header], 0),
        header === "Conversion Rate" ? "%" : ""
      )
    );
  };

  const formatCell = (header, value) => {
    if (header === "Conversion Rate") {
      return `${value}%`;
    } else if (header === "Revenue") {
      return formatValueWithSymbol("$", kSeparatorDecimal(value, 0, 2));
    } else {
      return kSeparatorDecimal(value, 0, 0);
    }
  };

  const processTableData = () => {
    if (tableData?.length) {
      const headers = tableData[0];
      const data = [headers];

      for (let i = 1; i < tableData.length; i++) {
        const rowData = generateDataObject(tableData[i], headers);
        data.push(processDataRowWithSymbols(rowData, headers));
      }
      setData(data);
    }
  };

  const processComparisonTableData = () => {
    if (tableData?.length && comparedTableData?.length) {
      const headers = tableData[0];
      const mergedData = [headers];
      const remainingData = [];
      const minLength = Math.min(tableData.length, comparedTableData.length);

      for (let i = 1; i < minLength; i++) {
        const row = tableData[i];
        const comparedRow = comparedTableData[i];

        const rowData = generateDataObject(row, headers);
        const comparedData = generateDataObject(comparedRow, headers);

        const diffRow = processComparedDataRows(rowData, headers, comparedData);
        const formattedDiffRow = diffRow.map((value, cellIndex) =>
          cellIndex === 0 ? value : getDiffArrow(value)
        );

        rowData["Conversion Rate"] = formatValueWithSymbol(
          "",
          kSeparatorDecimal(rowData["Conversion Rate"], 0),
          "%"
        );
        rowData["Revenue"] = formatValueWithSymbol(
          "$",
          kSeparatorDecimal(rowData["Revenue"], 0),
          ""
        );
        comparedData["Conversion Rate"] = formatValueWithSymbol(
          "",
          kSeparatorDecimal(comparedData["Conversion Rate"], 0),
          "%"
        );
        comparedData["Revenue"] = formatValueWithSymbol(
          "$",
          kSeparatorDecimal(comparedData["Revenue"], 0),
          ""
        );

        mergedData.push([
          `d/${rowData[headers[0]]}`,
          ...headers
            .slice(1)
            .map((header) => kSeparatorDecimal(rowData[header]), 0),
        ]);
        mergedData.push([
          `c/${comparedData[headers[0]]}`,
          ...headers
            .slice(1)
            .map((header) => kSeparatorDecimal(comparedData[header]), 0),
        ]);
        mergedData.push(["% change", ...formattedDiffRow.slice(1)]);
      }

      for (let i = minLength; i < tableData.length; i++) {
        const rowData = generateDataObject(tableData[i], headers);
        remainingData.push([
          `d/${rowData[headers[0]]}`,
          ...processDataRowWithSymbols(rowData, headers).slice(1),
        ]);
      }
      for (let i = minLength; i < comparedTableData.length; i++) {
        const comparedData = generateDataObject(comparedTableData[i], headers);
        remainingData.push([
          `c/${comparedData[headers[0]]}`,
          ...processDataRowWithSymbols(comparedData, headers).slice(1),
        ]);
      }
      setData(mergedData);
      setCompareRemainingData(remainingData);
    }
  };

  const processEntryMatchComparisonTableData = () => {
    if (tableData?.length && comparedTableData?.length) {
      const data = tableData.map((row, index) => {
        if (index === 0) {
          return row;
        }

        const label = row[0];
        const comparedRow = comparedTableData.find(
          (comparedRow) => comparedRow[0] === label
        );

        if (comparedRow) {
          const changes = row.slice(1).map((value, i) => {
            if (tableData[0][i + 1] === "Conversion Rate") {
              return calculateConversionRateDifference(
                value,
                comparedRow[i + 1]
              );
            } else {
              return calculatePercentage(value, comparedRow[i + 1]);
            }
          });

          const formattedRows = row
            .slice(1)
            .map((val, i) => formatCell(tableData[0][i + 1], val));
          const formattedComparedRow = comparedRow
            .slice(1)
            .map((val, i) => formatCell(tableData[0][i + 1], val));

          const formattedChanges = changes.map((change, i) =>
            getDiffArrow(change)
          );

          const combinedRows = [
            ["d/", ...formattedRows],
            ["c/", ...formattedComparedRow],
            ["% change", ...formattedChanges],
          ];

          return {
            label: label,
            rows: combinedRows,
          };
        } else {
          const emptyRow = row.map((_) => 0);

          const formattedEmptyRow = emptyRow
            .slice(1)
            .map((val, i) => formatCell(tableData[0][i + 1], val));
          const emptyChange = row.map((_) => "--%");

          const combinedRows = [
            [
              "d/",
              ...row
                .slice(1)
                .map((val, i) => formatCell(tableData[0][i + 1], val)),
            ],
            ["c/", ...formattedEmptyRow],
            ["% change", ...emptyChange.slice(1)],
          ];

          return {
            label: label,
            rows: combinedRows,
          };
        }
      });
      setData(data);
    } else {
      setData([]);
    }
  };

  // Process table data for comparison or standalone display
  useEffect(() => {
    if (isCompare) {
      isTrend && isDateCompareOn
        ? processComparisonTableData()
        : processEntryMatchComparisonTableData();
    } else {
      processTableData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompare, tableData, comparedTableData]);

  return (
    <div className="dashboard-table-wrapper">
      {!isCompare && !!data && (
        <Table
          data={!isLazyDisplay || isViewAllData ? data : data.slice(0, 21)}
          widget={widget}
        />
      )}
      {isCompare && isTrend && isDateCompareOn && !!data && (
        <ComparisonTable
          data={data}
          remainData={compareRemainingData}
          widget={widget}
        />
      )}
      {isCompare &&
        ((!isTrend && isDateCompareOn) || isSegmentCompareOn) &&
        !!data && (
          <EntryMatchComparisonTable
            data={!isLazyDisplay || isViewAllData ? data : data.slice(0, 21)}
            widget={widget}
            dataLabel={
              isDateCompareOn
                ? `${startDate.format("YYYY-MM-DD")} - ${endDate.format(
                    "YYYY-MM-DD"
                  )}`
                : "Selected Segments"
            }
            comparedDataLabel={
              isDateCompareOn
                ? `${compareStartDate.format("YYYY-MM-DD")} -
            ${compareEndDate.format("YYYY-MM-DD")}`
                : "Comparison Segments"
            }
          />
        )}
      {!!isLazyDisplay && data && data.length > 21 && (
        <div className="btn-view-all">
          <Button onClick={handleViewAllData} variant="link">
            {!isViewAllData ? "View All Data" : "View Top 20"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DashboardTable;

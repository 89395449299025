import React from "react";
import "./style.scss";
import UpgradeFlag from "../../../../components/app/FilterPanel/components/UpgradeFlag";

const TrackingCard = ({
  children,
  id,
  cardClass = "",
  titleClass = "",
  labelClass = "",
  title,
  switchComponent,
  flag,
}) => {
  return (
    <div id={id} className={"card card-body mb-3 " + cardClass}>
      <div className={`tracking-card-header ${titleClass}`}>
        <div className="tracking-card-title">
          <span className={labelClass}>
            <b>{title}</b>
          </span>
          {switchComponent && switchComponent}
        </div>
        {!!flag && (
          <div id={id + "flag"} className="card-flag">
            <UpgradeFlag flagContent={flag} type="plain" id={id} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default TrackingCard;

import React, { useEffect } from "react";
import SideBarNav from "../SideBarNav";
import TopBarNav from "../TopBarNav";

const AccountLayout = (param) => {
  const { title, children, tabTitle } = param;
  document.title = tabTitle || title;

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "/theme.js";
    document.head.appendChild(script);
    return () => script.parentNode.removeChild(script);
  }, []);

  return (
    <div id="wrapper">
      <TopBarNav />

      <div className="d-flex wrapper-content">
        <SideBarNav />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <div className="layout-header">
              <div className="d-sm-flex align-items-center justify-content-between">
                <span className="page-title">{title}</span>
              </div>
            </div>
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;

import axios from "axios";
import { getAuth } from "../lib/firebase";
import config from "../config/config.json";

/**
 * @typedef {Object} Location
 * @property {string} id - Location unique identifier
 * @property {string} name - Location name
 * @property {string} apiServer - API server URL
 */

/**
 * @typedef {Object} CreateAccountParams
 * @property {string} name - Account name
 * @property {string} location - Location ID
 * @property {string} featureSet - Feature set ID
 * @property {number} dataRetentionDays - Number of days to retain data
 */

/**
 * @typedef {Object} CreateAccountResponse
 * @property {string} accountId - Created account ID
 */

/**
 * @typedef {Object} FeatureSet
 * @property {string} name - Feature set name
 * @property {number} SessionReplaySamplingRate - Session replay sampling rate percentage
 * @property {boolean} apiErrorAvailable - Whether API error tracking is available
 * @property {number} contentLoadLookbackDays - Number of days for content load lookback (-1 for unlimited, 0 for not available)
 * @property {number} datalayerLookbackDays - Number of days for datalayer lookback (-1 for unlimited, 0 for not available)
 * @property {number} maxProfiles - Maximum number of profiles allowed
 */

/**
 * @typedef {Object} Profile
 * @property {string} name
 * @property {boolean} isCurrentUserAdmin - Is logged in user an admin
 * @property {string} apiServer - Data location api sub domain
 */


// Create axios instance with default config
const api = axios.create({
  baseURL: config.apiUi2HostPath + "/api2",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests that need it
const getAuthHeaders = async () => {
  const token = await getAuth().currentUser.getIdToken();
  return {
    Authorization: `Bearer ${token}`
  };
};

/**
 * Get available locations for data processing and storage
 * @returns {Promise<Location[]>} Array of available locations
 * @throws {Error} If the request fails
 */
export const getLocations = async () => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.get('/locations', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
};

/**
 * Get available feature sets
 * @returns {Promise<FeatureSet[]>} Array of available feature sets
 * @throws {Error} If the request fails
 */
export const getFeatureSets = async () => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.get('/feature-sets', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching feature sets:', error);
    throw error;
  }
};

/**
 * Get a profile by ID
 * @returns {Promise<Profile>} Profile found
 * @throws {Error} If the request fails
 */
export const getProfileById = async (profileId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.get(`/profiles/${profileId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

/**
 * Delete an account
 * @param {string} accountId - ID of the account to delete
 * @returns {Promise<{accountId: string, accountName: string}>} Deleted account details
 * @throws {Error} If the request fails
 */
export const deleteAccount = async (accountId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.delete(`/accounts/${accountId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error deleting account:', error);
    throw error;
  }
};

/**
 * Delete a profile
 * @param {string} profileId - ID of the profile to delete
 * @returns {Promise<{profileIdDeleted: string, comment: string, maxProfiles: number, currentProfiles: number}>} Deleted profile details
 * @throws {Error} If the request fails
 */
export const deleteProfile = async (profileId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.delete(`/profiles/${profileId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error deleting profile:', error);
    throw error;
  }
};

/**
 * Create a new profile
 * @param {Object} params
 * @param {string} params.accountId - ID of the account to create profile under
 * @param {string} params.name - Profile name
 * @param {string} params.timezone - Timezone for data processing
 * @param {string} params.domains - Comma-separated list of domains
 * @returns {Promise<{profileId: string}>} Created profile details
 * @throws {Error} If the request fails
 */
export const createProfile = async (params) => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.post('/profiles', params, { headers });
    return response.data;
  } catch (error) {
    console.error('Error creating profile:', error);
    throw error;
  }
};

export const createAccount = async (params) => {
  try {
    const headers = await getAuthHeaders();
    const response = await api.post('/accounts', params, { headers });
    return response.data;
  } catch (error) {
    console.error('Error creating account:', error);
    throw error;
  }
};

import React, { useContext } from "react";
import { UserDataContext } from "../../../../components/app/UserData";
import { fetchNoteById } from "./../../../../lib/firebase/notes";
import { captureExceptionToSentry } from "../../../../utils";

const clickmapDataFilters = require("../../../../inc/clickmapFilters.json");

function NoteRedirect(props) {
  const noteId = props.match.params.noteId;
  const profileId = props.match.params.profileId;
  const { setIsUserHasProfileAccess } = useContext(UserDataContext);

  React.useEffect(() => {
    if (noteId) {
      fetchNoteById(profileId, noteId)
        .then((res) => {
          const data = res.data();
          const sessionData = data?.sessionKey.split(".");
          const analyticsMode = data?.analyticsMode;
          const startDate = data?.startDate;
          const endDate = data?.endDate;
          const filter = data?.filter;
          const segments = {};
          const clickmapDataFilter =
            clickmapDataFilters[data?.clickmapDataFilter];

          if (data && data.segments && data.segments.length !== 0) {
            data.segments.forEach((segmentId) => {
              segments[segmentId] = true;
            });
          }

          if (analyticsMode === "moment" || !analyticsMode) {
            props.history.push(
              `/profile/${profileId}/replay/session/${sessionData[0]}.${sessionData[1]}/${sessionData[2]}`,
              {
                noteData: {
                  id: noteId,
                  time: data.noteTime,
                  activeTab: "notes",
                },
                from: props.history.location.pathname,
              }
            );
          } else {
            props.history.push(
              `/profile/${profileId}/replay/session/${sessionData[0]}.${sessionData[1]}/${sessionData[2]}`,
              {
                replayOptions: {
                  activeTab: "analytics",
                  analyticsMode: analyticsMode,
                  segments: segments,
                  filter: filter,
                  startDate: startDate.toDate(),
                  endDate: endDate.toDate(),
                  noteId: noteId,
                  time: data.noteTime,
                  clickmapDataFilter: clickmapDataFilter,
                },
                from: props.history.location.pathname,
              }
            );
          }
        })
        .catch((err) => {
          // This is a "Missing or insufficient permissions" error
          if (err.code === "permission-denied") {
            console.warn("User does not have access to this profile");
            setIsUserHasProfileAccess(false);
          }
          captureExceptionToSentry(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId]);

  return <div></div>;
}

NoteRedirect.propTypes = {};
NoteRedirect.defaultProps = {};

export default NoteRedirect;

import React, { useState } from "react";
import Alert from "../../../../components/share/Alert";
import TextField from "../../../../components/share/fields/TextField";
import SubmitButtons from "../../../../components/share/fields/SubmitButtons";
import { deleteProfile } from "../../../../api/apiGlobal";

const DeleteProfile = () => {
  const [profileId, setProfileId] = useState("");
  const [confirm, setConfirm] = useState("");
  const [processing, setProcessing] = useState(false);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (confirm !== "delete") {
      setAlert({
        show: true,
        type: "danger",
        message: "Please type `delete` to confirm",
        count: alert.count + 1,
      });
      return;
    }
    setProcessing(true);

    try {
      const response = await deleteProfile(profileId);
      setAlert({
        show: true,
        type: "info",
        message: `Profile ${profileId} was deleted successfully.`,
        count: alert.count + 1,
      });
      // Clear form
      setProfileId("");
      setConfirm("");
    } catch (error) {
      const errMsg = error?.response?.data?.message || "Oops, something went wrong. Please try again later.";
      setAlert({
        show: true,
        type: "danger",
        message: errMsg,
        count: alert.count + 1,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <TextField
                handleChange={(e) => setProfileId(e.target.value)}
                id="profile-id"
                label="Profile Id"
                placeholder="xxxx"
                text={profileId}
                helpText="Profile id to delete."
              />

              <TextField
                handleChange={(e) => setConfirm(e.target.value)}
                id="confirm"
                label="Confirm"
                placeholder="Type `delete` to confirm"
                text={confirm}
                helpText="Type `delete` to confirm"
              />

              <SubmitButtons
                processing={processing}
                backButtonTo="/"
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteProfile;

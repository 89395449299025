import React, { useContext, useState } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import Alert from './../../../../components/share/Alert';
import { Link } from "react-router-dom";
import { UserDataContext } from "./../../../../components/app/UserData";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import TimezoneField from "./../../../../components/share/fields/TimezoneField";
import TextField from "./../../../../components/share/fields/TextField";
import TextareaField from "./../../../../components/share/fields/TextareaField";
import SubmitButtons from "./../../../../components/share/fields/SubmitButtons";
import { createProfile } from './../../../../api/apiGlobal';

const CreateProfile = (param) => {
  const { authUser } = useContext(AuthContext);
  const accountId = param.match.params.accountId;
  const { access, loadAccountsThenSetAccess } = useContext(UserDataContext);

  const [timezone, setTimezone] = useState('Australia/Sydney');
  const [profileName, setProfileName] = useState('');
  const [domains, setDomains] = useState('');
  const [processing, setProcessing] = useState(false);
  const [alert, setAlert] = useState({
    'show': false,
    'type': '',
    'message': '',
    'count': 0
  });

  let accountName = '';
  if (access.loadingAccess === false && access.accounts !== null) {
    for (var i = 0; i < access.accounts.length; i++) {
      if (accountId === access.accounts[i].id && access.accounts[i].isAdmin) {
        accountName = access.accounts[i].name;
      }
    }
  }

  return (
    <>
      {access.loadingAccess ? (
        <div className="col-12 mb-4">
                <span>
                  <i className="fa fa-spinner fa-spin" />
                                Loading accounts...
                            </span>
              </div>
            ) : (
                <>
                  {accountName !== '' ? (
                    <>
                      <div className="row">
                        <Alert show={alert.show} type={alert.type} message={alert.message} count={alert.count} />
                      </div>
                      <div className="row">
                        <div className="col mb-4">
                          <div className="card shadow mb-4">
                            <div className="card-body">
                              <TextField id="account-name" label="Account Name" readOnly={true} text={accountName} plainText={true}></TextField>
                              <TextField handleChange={(e) => {
                                setProfileName(e.target.value);
                              }} id="profile-name" label="Profile Name" placeholder="e.g. Production websites" text={profileName} helpText="Profile name is to identify the websites tracked in the profile."></TextField>
                              <TimezoneField setTimezone={setTimezone} defaultTimezone={timezone} helpText="Set your data processing timezone. The setting cannot be changed after it's set."></TimezoneField>
                              <TextareaField text={domains} placeholder="e.g. yourcompany.com" label="Domains" id="domains" helpText="List all the top domains of your website journey, including your payment processors (e.g. paypal.com). One line per domain." handleChange={(e) => {
                                setDomains(e.target.value);
                              }}></TextareaField>
                              <SubmitButtons processing={processing} backButtonTo="/" handleClick={async (e) => {
                                e.preventDefault();
                                setAlert({ show: false, type: '', message: '', count: 0 });
                                setProcessing(true);
                                try {
                                  const response = await createProfile({
                                    accountId: accountId,
                                    name: profileName,
                                    timezone: timezone,
                                    domains: domains.split("\n").join(",")
                                  });

                                  if (response.status !== 200) {
                                    setProcessing(false);
                                    setAlert({
                                      show: true,
                                      type: 'danger',
                                      message: response.result || 'Oops, something went wrong. Please try again later.',
                                      count: alert.count + 1
                                    });
                                    return;
                                  }

                                  const newProfileId = response.data?.profileId;
                                  if(newProfileId === undefined) {
                                    setProcessing(false);
                                    setAlert({
                                      show: true,
                                      type: 'danger',
                                      message: 'Oops, something went wrong. Please try again later.',
                                      count: alert.count + 1
                                    });
                                    return;
                                  } else {
                                    setAlert({
                                      show: true,
                                      type: 'success',
                                      message: <>Profile {newProfileId} created, see <Link to={`/profile/${newProfileId}/admin/code`} target="_blank" rel="noopener noreferrer">install code <HiOutlineExternalLink size={16} /></Link></>,
                                      count: alert.count + 1
                                    });
                                  }
                                  setProcessing(false);
                                  
                                  // update the access data
                                  loadAccountsThenSetAccess();
                                } catch (error) {
                                  setProcessing(false);
                                  setAlert({
                                    show: true,
                                    type: 'danger',
                                    message: error?.response?.data?.message || 'Oops, something went wrong. Please try again later.',
                                    count: alert.count + 1
                                  });
                                }
                              }}></SubmitButtons>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                      <>
                        <div className="row">
                          <Alert show={true} type={"danger"} message={"Access denied."} count={0} allowClose={false} />
                        </div>
                        <Link className="btn btn-secondary btn-sm" to="/">Back</Link>
                      </>
                    )}
                </>
              )}
    </>

  )
}

export default CreateProfile;

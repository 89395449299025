import React, { useContext, useState, useEffect, useMemo } from "react";
import Alert from "./../../../../components/share/Alert";
import TextareaField from "./../../../../components/share/fields/TextareaField";
import TextField from "./../../../../components/share/fields/TextField";
import api from "./../../../../api";
import LoaderWithText from "./../../../../components/share/LoaderWithText";
import RageClickSettings from "./RageClickSettings";
import { Button, Switch } from "./../../../../components/share/InsightUI";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { fetchProfile } from "../../../../lib/firebase/profile";
import TrackingCard from "./TrackingCard";
import ApiErrorSettings from "./ApiErrorSettings";
import LightModeSetting from "./lightModeSettings";
import { parseUrl } from "../../../../utils";
import { UserDataContext } from "../../../../components/app/UserData";

const TrackingCodeSettings = (props) => {
  const { authUser } = useContext(AuthContext);
  const { featureFlags } = useContext(UserDataContext);
  const profileId = props.match.params.profileId;

  const [datalayerName, setDatalayerName] = useState("");
  const [allowedFields, setAllowedFields] = useState([]);
  const [disabledAllowedFields, setDisabledAllowedFields] = useState(false);
  const [maskingSelectors, setMaskingSelectors] = useState([]);
  const [mutationExclusion, setMutationExclusion] = useState([]);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  // Light Mode Settings
  const [lightModeEnabled, setLightModeEnabled] = useState(false);
  const [fullModeSessionsSampleRate, setFullModeSessionsSampleRate] =
    useState();
  const [URLBlackList, setURLBlackList] = useState([]);
  const [URLWhiteList, setURLWhiteList] = useState([]);

  // Rage Click Settings
  const [rageClickClicks, setRageClickClicks] = useState("");
  const [rageClickIntervalMs, setRageClickIntervalMs] = useState("");
  const [rageClickExclusion, setRageClickExclusion] = useState([]);
  const [rageClickEnabled, setRageClickEnabled] = useState(false);

  // API Error Tracking
  const [apiErrorEndpoints, setApiErrorEndpoints] = useState([]);
  const [apiErrorEndpointsWithPayload, setApiErrorEndpointsWithPayload] =
    useState([]);
  const [apiErrorEnabled, setApiErrorEnabled] = useState(false);

  const handleApiErrorEnabled = () => {
    setApiErrorEnabled(!apiErrorEnabled);
  };

  const handleRageClickEnabled = () => {
    // Clear the rageclick settings when disable
    if (rageClickEnabled) {
      setRageClickClicks("");
      setRageClickIntervalMs("");
      setRageClickExclusion([]);
    }

    // Set default rageClick setting when enable
    if (!rageClickEnabled) {
      setRageClickClicks("4");
      setRageClickIntervalMs("2000");
      setRageClickExclusion([]);
    }

    setRageClickEnabled(!rageClickEnabled);
  };

  const handleClickSave = () => {
    setIsFormProcessing(true);
    api
      .updateTracking(authUser, {
        profileId,
        allowedFields: allowedFields.join("\n"),
        maskingSelectors: maskingSelectors.join("\n"),
        mutationExclusion: mutationExclusion.join("\n"),
        datalayerName,
        rageClickIntervalMs,
        rageClickClicks,
        rageClickExlusions: rageClickExclusion.join("\n"),
        rageClickEnabled,
        apiErrorEnabled,
        apiErrorEndpoints: apiErrorEnabled ? apiErrorEndpoints.join("\n") : "",
        apiErrorEndpointsWithPayload: apiErrorEnabled
          ? apiErrorEndpointsWithPayload.join("\n")
          : "",
      })
      .then((res) => {
        if (res.data.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message:
              "Your tracking settings have been published. It will take a few minutes to take effect",
            count: alert.count + 1,
          });
        }
        setIsFormProcessing(false);
      });
  };

  const handleChangeRageClickNumber = (e) => {
    setRageClickClicks(e.currentTarget.value);
  };

  const handleChangeRageClickTime = (e) => {
    setRageClickIntervalMs(e.currentTarget.value);
  };

  const handleChangeAllowedFields = (e) => {
    setAllowedFields(e.target.value.split("\n"));
  };

  const handleChangeMaskingSelectors = (e) => {
    setMaskingSelectors(e.target.value.split("\n"));
  };

  const handleChangeMutationExclusion = (e) => {
    setMutationExclusion(e.target.value.split("\n"));
  };

  const handleChangeRageClickExclusion = (e) => {
    setRageClickExclusion(e.currentTarget.value.split("\n"));
  };

  // API Error tracking
  const handleApiErrorEndpoints = (e) => {
    setApiErrorEndpoints(e.currentTarget.value.split("\n"));
  };

  const handleApiErrorEndpointsWithPayload = (e) => {
    setApiErrorEndpointsWithPayload(e.currentTarget.value.split("\n"));
  };

  const handleChangeDatalayerName = (e) => {
    setDatalayerName(e.target.value);
  };

  const isSelectorsValid = (selectors) => {
    let isValid = true;
    selectors.forEach((selector) => {
      try {
        document.querySelector(selector);
        return;
      } catch (error) {
        setAlert({
          show: true,
          type: "danger",
          message: `${selector} is not a valid selector`,
          count: alert.count + 1,
        });
        isValid = false;
      }
    });

    return isValid;
  };

  const isApiEndpointsValid = (apiEndpoints) => {
    let isValid = true;
    apiEndpoints.forEach((apiEndpoint) => {
      const parsedURL = parseUrl(apiEndpoint);
      if (parsedURL !== "") {
        return;
      } else {
        setAlert({
          show: true,
          type: "danger",
          message: `${apiEndpoint} is not a valid API Endpoint`,
          count: alert.count + 1,
        });
        isValid = false;
      }
    });
    return isValid;
  };

  const isValidRange = (value, min, max, errorMessage) => {
    if (!rageClickEnabled) return true;
    if (value === "") {
      setAlert({
        show: true,
        type: "danger",
        message: errorMessage,
        count: alert.count + 1,
      });
      return false;
    }
    const numValue = Number(value);
    const isValid = numValue >= min && numValue <= max;
    if (!isValid) {
      setAlert({
        show: true,
        type: "danger",
        message: errorMessage,
        count: alert.count + 1,
      });
    }
    return isValid;
  };

  const isRageClickNumberValid = useMemo(() => {
    return (
      isValidRange(
        rageClickClicks,
        2,
        50,
        "Rage click number must be between 2 and 50"
      ) || !rageClickEnabled
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rageClickClicks, rageClickEnabled]);

  const isRageClickIntervalMsValid = useMemo(() => {
    return (
      isValidRange(
        rageClickIntervalMs,
        100,
        10000,
        "Rage click interval must be between 100 and 10000 milliseconds"
      ) || !rageClickEnabled
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rageClickIntervalMs, rageClickEnabled]);

  const isAllowedFieldsValid = useMemo(() => {
    return allowedFields.length !== 0 && allowedFields[0] !== ""
      ? isSelectorsValid(allowedFields)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedFields]);

  const isMaskingSelectorsValid = useMemo(() => {
    return maskingSelectors.length !== 0 && maskingSelectors[0] !== ""
      ? isSelectorsValid(maskingSelectors)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maskingSelectors]);

  const isMutationExclusionValid = useMemo(() => {
    return mutationExclusion.length !== 0 && mutationExclusion[0] !== ""
      ? isSelectorsValid(mutationExclusion)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationExclusion]);

  const isRageClickExclusionValid = useMemo(() => {
    return rageClickExclusion.length !== 0 && rageClickExclusion[0] !== ""
      ? isSelectorsValid(rageClickExclusion)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rageClickExclusion]);

  const isApiErrorEndpointsValid = useMemo(() => {
    return apiErrorEndpoints.length !== 0 && apiErrorEndpoints[0] !== ""
      ? isApiEndpointsValid(apiErrorEndpoints)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrorEndpoints, apiErrorEnabled]);

  const isApiErrorEndpointsWithPayload = useMemo(() => {
    return apiErrorEndpointsWithPayload.length !== 0 &&
      apiErrorEndpointsWithPayload[0] !== ""
      ? isApiEndpointsValid(apiErrorEndpointsWithPayload)
      : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrorEndpointsWithPayload, apiErrorEnabled]);

  const isAtLeastSingelApi = useMemo(() => {
    if (!apiErrorEnabled) return true;
    return (
      (apiErrorEndpoints.length !== 0 && apiErrorEndpoints[0] !== "") ||
      (apiErrorEndpointsWithPayload.length !== 0 &&
        apiErrorEndpointsWithPayload[0] !== "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrorEndpoints, apiErrorEndpointsWithPayload, apiErrorEnabled]);

  const isSaveDisabled =
    isFormProcessing ||
    !isAllowedFieldsValid ||
    !isMaskingSelectorsValid ||
    !isMutationExclusionValid ||
    !isRageClickExclusionValid ||
    !isRageClickNumberValid ||
    !isRageClickIntervalMsValid ||
    !isApiErrorEndpointsValid ||
    !isApiErrorEndpointsWithPayload ||
    !isAtLeastSingelApi;

  // Alert if there is not any api endpoint
  useEffect(() => {
    if (!isAtLeastSingelApi) {
      setAlert({
        show: true,
        type: "danger",
        message:
          "API Error Tracking need at least one endpoint to be monitored",
        // eslint-disable-next-line
        count: alert.count + 1,
      });
    }
    // eslint-disable-next-line
  }, [isAtLeastSingelApi]);

  useEffect(() => {
    //remove alert when the form is valid
    if (
      isAllowedFieldsValid &&
      isMaskingSelectorsValid &&
      isMutationExclusionValid &&
      isRageClickExclusionValid &&
      isRageClickNumberValid &&
      isRageClickIntervalMsValid &&
      isApiErrorEndpointsValid &&
      isApiErrorEndpointsWithPayload &&
      isAtLeastSingelApi
    )
      setAlert({
        show: false,
        type: "",
        message: "",
        count: 0,
      });
  }, [
    isAllowedFieldsValid,
    isMaskingSelectorsValid,
    isMutationExclusionValid,
    isRageClickExclusionValid,
    isRageClickNumberValid,
    isRageClickIntervalMsValid,
    isApiErrorEndpointsValid,
    isApiErrorEndpointsWithPayload,
    isAtLeastSingelApi,
  ]);

  useEffect(() => {
    setIsLoading(true);
    if (!authUser.isAdmin) {
      props.history.push("/");
    }
    // Keep this fetch profile wich is quite detailed
    fetchProfile({ profileId }).then((res) => {
      if (res && res.data && res.data.data) {
        const data = res.data.data;
        if (data.FieldAllowList) {
          setAllowedFields(data.FieldAllowList);
        }
        if (data.DisabledFieldAllowList) {
          setDisabledAllowedFields(true);
        }
        if (data.ContentBlockList) {
          setMaskingSelectors(data.ContentBlockList);
        }
        if (data.dataLayerName) {
          setDatalayerName(data.dataLayerName);
        }
        if (data.MutationExclusion) {
          setMutationExclusion(data.MutationExclusion);
        }
        // Rage Click Settings
        if (data.rageClickSettings && data.rageClickSettings.enabled) {
          setRageClickEnabled(data.rageClickSettings.enabled);
          if (data.rageClickSettings.clickIntervalMs)
            setRageClickIntervalMs(data.rageClickSettings.clickIntervalMs);
          if (data.rageClickSettings.clickClicks)
            setRageClickClicks(data.rageClickSettings.clickClicks);
          if (data.rageClickSettings.clickExclusionList)
            setRageClickExclusion(data.rageClickSettings.clickExclusionList);
        }
        // Api Error Settings
        if (data.apiErrorSettings && data.apiErrorSettings.enabled) {
          setApiErrorEnabled(data.apiErrorSettings.enabled);
          if (data.apiErrorSettings.endpoints) {
            setApiErrorEndpoints(data.apiErrorSettings.endpoints);
          }
          if (data.apiErrorSettings.endpointsWithPayload) {
            setApiErrorEndpointsWithPayload(
              data.apiErrorSettings.endpointsWithPayload
            );
          }
        }
        // Light Mode Setting
        if (data.liteTrackingSettings && data.liteTrackingSettings.enabled) {
          setLightModeEnabled(data.liteTrackingSettings.enabled);
          if (data.liteTrackingSettings.fullModeSessionsSampling) {
            setFullModeSessionsSampleRate(
              data.liteTrackingSettings.fullModeSessionsSampling
            );
          }
          if (data.liteTrackingSettings.fullModeUrls) {
            setURLWhiteList(data.liteTrackingSettings.fullModeUrls);
          }
          if (data.liteTrackingSettings.liteModeUrls) {
            setURLBlackList(data.liteTrackingSettings.liteModeUrls);
          }
        }
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              {isLoading && (
                <LoaderWithText className="my-4" text="Loading Profile Data" />
              )}
              {!isLoading && (
                <>
                  <TrackingCard
                    id={"pii-settings"}
                    title={"Privacy Protection"}
                  >
                    {!disabledAllowedFields && (
                      <TextareaField
                        text={allowedFields.join("\n")}
                        placeholder=""
                        label="Allowed Fields"
                        id="allowedFields"
                        helpText="List of allowed field element CSS selectors. One line per CSS selector."
                        handleChange={handleChangeAllowedFields}
                        isValid={isAllowedFieldsValid}
                      />
                    )}
                    <TextareaField
                      text={maskingSelectors.join("\n")}
                      placeholder=""
                      label="Masking Selectors"
                      id="maskingSelectors"
                      helpText="List of masking content element CSS selectors. One line per CSS selector."
                      handleChange={handleChangeMaskingSelectors}
                      isValid={isMaskingSelectorsValid}
                    />
                    <TextareaField
                      text={mutationExclusion.join("\n")}
                      placeholder=""
                      label="Excluded Elements for Mutation Tracking"
                      id="mutationExclusion"
                      helpText="List of element CSS selectors that will be excluded from DOM mutation tracking."
                      handleChange={handleChangeMutationExclusion}
                      isValid={isMutationExclusionValid}
                    />
                  </TrackingCard>
                  <TrackingCard
                    id={"pii-tracking-settings"}
                    title={"Data Layer"}
                  >
                    <TextField
                      text={datalayerName}
                      placeholder=""
                      label="dataLayer Object Name"
                      id="datalayer-name"
                      helpText="Put in the data layer object name to enable capturing data layer events."
                      handleChange={handleChangeDatalayerName}
                    />
                  </TrackingCard>
                  <TrackingCard
                    id={"rage-click-settings"}
                    labelClass={"mr-2"}
                    title={"Rage Click"}
                    switchComponent={
                      <Switch
                        id="rageClickEnabled"
                        value={rageClickEnabled}
                        isChecked={rageClickEnabled}
                        onChange={handleRageClickEnabled}
                      />
                    }
                  >
                    {rageClickEnabled && (
                      <RageClickSettings
                        rageClickClicks={rageClickClicks}
                        rageClickEnabled={rageClickEnabled}
                        handleChangeRageClickExclusion={
                          handleChangeRageClickExclusion
                        }
                        handleChangeRageClickNumber={
                          handleChangeRageClickNumber
                        }
                        handleChangeRageClickTime={handleChangeRageClickTime}
                        rageClickExclusion={rageClickExclusion}
                        rageClickIntervalMs={rageClickIntervalMs}
                        isRageClickNumberValid={isRageClickNumberValid}
                        isRageClickIntervalMsValid={isRageClickIntervalMsValid}
                        isRageClickExclusionValid={isRageClickExclusionValid}
                      />
                    )}
                  </TrackingCard>
                  <TrackingCard
                    id={"api-error-tracking"}
                    cardClass={
                      featureFlags.isApiErrorFlag ? "upgrade-card" : ""
                    }
                    labelClass={"mr-2"}
                    title={"API Error Tracking"}
                    switchComponent={
                      <Switch
                        id="apiErrorEnabled"
                        value={apiErrorEnabled}
                        isChecked={apiErrorEnabled}
                        onChange={handleApiErrorEnabled}
                        disabled={featureFlags.isApiErrorFlag}
                      />
                    }
                    flag={
                      featureFlags.isApiErrorFlag &&
                      " Unlock API Error Tracking"
                    }
                  >
                    <ApiErrorSettings
                      apiErrorEndpoints={apiErrorEndpoints}
                      apiErrorEndpointsWithPayload={
                        apiErrorEndpointsWithPayload
                      }
                      handleApiErrorEndpoints={handleApiErrorEndpoints}
                      handleApiErrorEndpointsWithPayload={
                        handleApiErrorEndpointsWithPayload
                      }
                      isApiErrorEndpointsValid={isApiErrorEndpointsValid}
                      isApiErrorEndpointsWithPayload={
                        isApiErrorEndpointsWithPayload
                      }
                      disabled={featureFlags.isApiErrorFlag}
                    />
                  </TrackingCard>
                  {lightModeEnabled && (
                    <TrackingCard
                      id={"light-mode"}
                      cardClass={"upgrade-card"}
                      labelClass={"mr-2"}
                      title={"Sampling Session Replay"}
                      flag={
                        featureFlags.isLiteTrackingFlag && "Unlock All Sessions"
                      }
                    >
                      <small className="mb-3 w-50">
                        <i className="fas fa-lightbulb mr-2" />
                        Sampling will restrict the number of Session Replays
                        captured, which will impact the data coverage for any
                        report using the “Content Element Loaded” filter
                      </small>
                      {
                        <LightModeSetting
                          fullModeSessionsSampleRate={
                            fullModeSessionsSampleRate
                          }
                          URLBlackList={URLBlackList}
                          URLWhiteList={URLWhiteList}
                        />
                      }
                    </TrackingCard>
                  )}
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={isSaveDisabled}
                      variant="primary"
                      onClick={handleClickSave}
                      size="small"
                    >
                      {isFormProcessing && (
                        <>
                          <span className="fa fa-spinner spin"></span>
                          {` `}
                        </>
                      )}
                      Save Settings
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingCodeSettings;

import React, { useEffect, useContext } from "react";
import "./style.scss";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { getFirstAndLastName } from "../../../../utils";

const HubSpotForm = () => {
  const { authUser } = useContext(AuthContext);

  const { firstName, lastName } = getFirstAndLastName(
    authUser.user.displayName
  );

  // Inject the HubSpot contact form into the page
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "41038237",
          formId: "f2d2b2fb-66de-436b-b0d9-bcc9fa0f14ed",
          target: "#hubspot-form-container",
          onReady: (form) => {
            // Function to pre-fill the form
            const fillForm = () => {
              try {
                const iframe = document.querySelector("iframe.hs-form-iframe");
                if (!iframe) return;

                const iframeDocument =
                  iframe.contentDocument || iframe.contentWindow.document;

                // Try to fill the form fields
                const inputs = {
                  email: authUser.user.email,
                  firstname: firstName,
                  lastname: lastName,
                };

                Object.entries(inputs).forEach(([name, value]) => {
                  const input = iframeDocument.querySelector(
                    `input[name="${name}"]`
                  );
                  if (input) {
                    input.value = value;
                    // Trigger input event to ensure HubSpot registers the change
                    const event = new Event("input", { bubbles: true });
                    input.dispatchEvent(event);
                  }
                });
              } catch (error) {
                console.error("Error filling HubSpot form:", error);
              }
            };

            // Try immediately in case iframe is already loaded
            fillForm();

            // Also set up listener for iframe load
            const observer = new MutationObserver((mutations, obs) => {
              const iframe = document.querySelector("iframe.hs-form-iframe");
              if (iframe) {
                iframe.addEventListener("load", fillForm);
                obs.disconnect(); // Stop observing once we find the iframe
              }
            });

            // Start observing the container for the iframe to appear
            observer.observe(
              document.getElementById("hubspot-form-container"),
              {
                childList: true,
                subtree: true,
              }
            );
          },
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, [authUser.user.email, firstName, lastName]);

  return <div id="hubspot-form-container" />;
};

const PlanUpgrade = () => {
  return (
    <div className="plan-upgrade-wrapper">
      <div className="w-layout-blockcontainer container-10 w-container">
        <h1 className="hero-h1">
          Upgrade Now to gain even more insights to improve digital experiences
          and drive conversions
        </h1>
        <div className="w-layout-grid grid-20">
          <div
            id="w-node-_3483ac88-e73f-e4ca-562e-f54955e7ee40-a039d73f"
            className="div-block-32"
          >
            <p className="hero-descr">
              Insightech has multiple subscription options to suit your
              organisation’s unique use cases. Contact our team to learn more
              about which options can unlock your team’s full potential.
            </p>
            <img
              src="https://cdn.prod.website-files.com/60c7fb47654103c5c41d3319/66177089744a9bca4a9c9a37_6372ffee1e22b7f87bbbb45f_point-and-click_%E2%80%93_opt1-p-800%20(1).png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 842px) 95vw, (max-width: 991px) 800px, (max-width: 1279px) 80vw, 800px"
              srcSet="https://cdn.prod.website-files.com/60c7fb47654103c5c41d3319/66177089744a9bca4a9c9a37_6372ffee1e22b7f87bbbb45f_point-and-click_%E2%80%93_opt1-p-800%20(1)-p-500.png 500w, https://cdn.prod.website-files.com/60c7fb47654103c5c41d3319/66177089744a9bca4a9c9a37_6372ffee1e22b7f87bbbb45f_point-and-click_%E2%80%93_opt1-p-800%20(1).png 800w"
              alt=""
            />
          </div>
          <div
            id="w-node-_3483ac88-e73f-e4ca-562e-f54955e7ee48-a039d73f"
            className="trial-form-grey"
          >
            <div id="w-node-_4393883d-d19b-b536-c7ff-cbfc1f6ed77c-a039d73f">
              <HubSpotForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanUpgrade;

import React, { useEffect, useState } from "react";
import { signinUser, addLog } from "./../../../lib/firebase";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Alert,
} from "./../../../components/share/InsightUI";
import withAuthMethods from "./../../../components/hoc/withAuthMethods";
import {
  signinFormValidator,
  signinCodeFormValidator,
  signinPasswordFormValidator,
} from "./../../../helpers/formValidators";
import { Formik } from "formik";
import { SIGN_IN } from "./../../../constants/log";
import uiApi from "./../../../api/api-ui";
import { IoIosTimer } from "react-icons/io";

import "./../common.scss";
import "./style.scss";
import { captureExceptionToSentry } from "../../../utils";

const SignInMethodLink = () => (
  <p className="signin-text text-center">
    <small>
      <Link to="/">Chose another Sign In method</Link>
    </small>
  </p>
);

const SignIn = (props) => {
  const [step, setStep] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [notRemember, setNotRemember] = useState(false);
  const [milliseconds, setMilliSeconds] = useState(60 * 1000);
  const [countDownStart, setCountDownStart] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    props.setSigninError(null);

    if (step === 1) {
      uiApi
        .verifyCode(emailAddress, values.code)
        .then((result) => {
          if (result.data.result === "success") {
            setStep(2);
          } else if (result.data.error === "too-many-attempts") {
            props.setSigninError(
              "Too many incorrect or expired code attempts. Please request a new code to continue."
            );
          } else {
            props.setSigninError(
              "The code you entered is incorrect or expired. Please try again or restart the sign-in process."
            );
          }
          setSubmitting(false);
        })
        .catch((err) => {
          props.setSigninError(
            "The code you entered is incorrect or expired. Please try again or restart the sign-in process."
          );
          setSubmitting(false);
        });
    } else if (step === 2) {
      signinUser(
        emailAddress,
        values.password,
        notRemember,
        (response) => {
          addLog(SIGN_IN, response.user.uid);
          const redirect = sessionStorage.getItem("redirect");
          if (redirect) {
            window.location = redirect;
            return;
          }
          window.location = "/";
        },
        (error) => {
          console.warn("Sign in error", error.message);
          if (error.message && error.message.includes("invalid-otp-code")) {
            props.setSigninError(
              "Your multi-factor verification has expired. Please restart the sign-in process."
            );
            captureExceptionToSentry(error);
            setSubmitting(false);
            return;
          }
          switch (error.code) {
            case "auth/wrong-password":
              props.setSigninError(
                "The password is invalid or the user does not have a password. Please use your Google or Microsoft account to sign in."
              );
              break;
            default:
              props.setSigninError(
                "The account has been temporarily disabled due to many failed login attempts."
              );
              break;
          }
          setSubmitting(false);
        }
      );
    } else {
      uiApi
        .generateCode(values.email)
        .then((result) => {
          setEmailAddress(values.email);
          setNotRemember(values.notRemember);
          setStep(1);
          setSubmitting(false);
          countDown();
        })
        .catch((err) => {
          props.setSigninError(
            "Oops, something went wrong! Please try again later."
          );
          setSubmitting(false);
        });
    }
  };

  const handleResent = () => {
    uiApi
      .generateCode(emailAddress)
      .then((result) => {
        setMilliSeconds(60 * 1000);
        countDown();
      })
      .catch((err) => {
        props.setSigninError(
          "Oops, something went wrong! Please try again later."
        );
      });
  };

  const countDown = () => {
    setCountDownStart(true);
  };

  const myTimer = () => {
    setMilliSeconds(milliseconds - 1000);
  };

  useEffect(() => {
    if (countDownStart) {
      const timer = setTimeout(myTimer, 1000);

      if (milliseconds === 0) {
        clearTimeout(timer);
        setCountDownStart(false);
      }

      // clear the timer when the component is unmounted
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milliseconds, countDownStart]);

  return (
    <div className="page-container container">
      <h1 className="h4 text-gray-900 mt-4 mb-5">Welcome back!</h1>
      {step === null && (
        <>
          <div className="signup-form">
            <Formik
              initialValues={{
                email: "",
                password: "",
                notRemember: false,
                code: "",
              }}
              validate={signinFormValidator}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  className="user"
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting || props.isBusy}
                >
                  {props.signinError && (
                    <Alert variant="danger">{props.signinError}</Alert>
                  )}
                  <p>
                    Please enter your email address to start the sign in
                    process, or sign in with your Google account and Microsoft
                    account.
                  </p>
                  <Input
                    required
                    isInvalid={!!(touched.email && errors.email)}
                    invalidFeedback={errors.email}
                    className="form-control-user"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    value={values.email}
                  />

                  <div className="form-row">
                    <Input
                      type="checkbox"
                      onChange={handleChange}
                      size="small"
                      className="col-md-6"
                      name="notRemember"
                      label="Require login every time"
                      labelTooltip="If checked, you'll need to log in each time you start a new tab.
                      If unchecked, you'll stay signed in indefinitely."
                    />
                    <div className="col-md-6 text-right">
                      <Link to="/reset-request">
                        <small className="font-weight-bold">
                          Forgot Password ?
                        </small>
                      </Link>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className="btn-user btn-block"
                    variant="primary"
                  >
                    Sign In with Email
                  </Button>
                  <hr />
                  <Button
                    type="button"
                    className="btn-user btn-google btn-block"
                    variant="primary"
                    onClick={props.handleGmailSignin}
                  >
                    <i className="fab fa-google fa-fw"></i>
                    {` `}
                    Sign In with Google
                  </Button>
                  <Button
                    type="button"
                    className="btn-user btn-dark btn-block"
                    variant="primary"
                    onClick={props.handleMicrosoftSignin}
                  >
                    <i className="fab fa-microsoft fa-fw"></i>
                    {` `}
                    Sign In with Microsoft
                  </Button>
                  <hr />
                </Form>
              )}
            </Formik>
          </div>
          <p className="signin-text">
            <small>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </small>
          </p>
        </>
      )}

      {step === 1 && (
        <>
          <div className="signup-form">
            <Formik
              initialValues={{
                email: "",
                password: "",
                notRemember: false,
                code: "",
              }}
              validate={signinCodeFormValidator}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  className="user"
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting || props.isBusy}
                >
                  {props.signinError && (
                    <Alert variant="danger">{props.signinError}</Alert>
                  )}
                  <p>
                    A verification code has been delivered to your email address
                    if you have a user account registered with the email
                    address. Please put in the verfication code to continue.
                    Don't forget to check your spam mail box if you haven't
                    received the code in your inbox.
                  </p>
                  <div className="row">
                    <div className="col-9">
                      <Input
                        required
                        isInvalid={!!(touched.code && errors.code)}
                        invalidFeedback={errors.code}
                        className="form-control-user"
                        name="code"
                        placeholder="Verification Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.code}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-3">
                      <Button
                        type="button"
                        className="btn-user btn-block"
                        variant={countDownStart ? "secondary" : "primary"}
                        disabled={countDownStart}
                        onClick={handleResent}
                      >
                        {countDownStart ? (
                          <div>
                            <IoIosTimer />
                            {` ${Math.floor(milliseconds / 1000)}s to resend`}
                          </div>
                        ) : (
                          "Resend"
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        type="submit"
                        className="btn-user btn-block"
                        variant="primary"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                  <hr />
                </Form>
              )}
            </Formik>
          </div>
          <SignInMethodLink />
        </>
      )}

      {step === 2 && (
        <>
          <div className="signup-form">
            <Formik
              initialValues={{
                email: "",
                password: "",
                notRemember: false,
                code: "",
              }}
              validate={signinPasswordFormValidator}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  className="user"
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting || props.isBusy}
                >
                  {props.signinError && (
                    <Alert variant="danger">{props.signinError}</Alert>
                  )}
                  <p>
                    Please enter your password to sign in. The password is case
                    sensitive.
                  </p>
                  <Input
                    required
                    isInvalid={!!(touched.password && errors.password)}
                    invalidFeedback={errors.password}
                    className="form-control-user"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    value={values.password}
                  />

                  <div className="form-group col-md-12">
                    <div className="col-md-12 text-right">
                      <Link to="/reset-request">
                        <small className="font-weight-bold">
                          Forgot Password ?
                        </small>
                      </Link>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className="btn-user btn-block"
                    variant="primary"
                  >
                    Sign In
                  </Button>
                  <hr />
                </Form>
              )}
            </Formik>
          </div>
          <SignInMethodLink />
        </>
      )}
    </div>
  );
};

export default withAuthMethods(SignIn);

import React from "react";
import NumberField from "../../../../components/share/fields/NumberField";
import TextareaField from "../../../../components/share/fields/TextareaField";

const LightModeSetting = ({
  fullModeSessionsSampleRate,
  URLBlackList,
  URLWhiteList,
}) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Sample Rate</label>
        <div className="col-sm-10 col-md-4 col-lg-6 row align-items-center">
          <NumberField
            number={fullModeSessionsSampleRate}
            min={0}
            max={100}
            label={"%"}
            disabled={true}
          />
        </div>
      </div>
      {URLWhiteList?.length > 0 && (
        <TextareaField
          text={URLWhiteList.join("\n")}
          placeholder=""
          label="Always Include"
          id={"URLWhiteList"}
          helpText="Always record replays on pages whose url contains one of these items"
          disabled={true}
        />
      )}
      <TextareaField
        text={URLBlackList.join("\n")}
        placeholder=""
        label="Always Exclude"
        id={"URLBlackList"}
        helpText="Never record replays on pages whose url contains one of these items"
        disabled={true}
      />
    </>
  );
};

export default LightModeSetting;

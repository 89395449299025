import React, { useContext, useState, useEffect } from "react";
import Alert from "../../../../components/share/Alert";
import TextField from "../../../../components/share/fields/TextField";
import { HiOutlineExternalLink } from "react-icons/hi";
import { Field } from "./../../../../components/share/InsightUI";
import SubmitButtons from "../../../../components/share/fields/SubmitButtons";
import { getLocations, createAccount, getFeatureSets } from "../../../../api/apiGlobal";

const CreateAccount = (param) => {

  const [accountName, setAccountName] = useState("");
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [featureSet, setFeatureSet] = useState("");
  const [dataRetention, setDataRetention] = useState(93); // Default to 3 months
  const [featureSets, setFeatureSets] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [createdAccountId, setCreatedAccountId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations and feature sets in parallel
        const [locationsData, featureSetsData] = await Promise.all([
          getLocations(),
          getFeatureSets()
        ]);

        // Set locations
        setLocations(locationsData);
        if (locationsData.length > 0) {
          setLocation(locationsData[0].id);
        }

        // Set feature sets
        setFeatureSets(featureSetsData);
        if (featureSetsData.length > 0) {
          setFeatureSet(featureSetsData[0].id);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setAlert(prev => ({
          show: true,
          type: "danger", 
          message: error?.response?.data?.message || "Failed to load required data",
          count: prev.count + 1
        }));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const handleSumbit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await createAccount({
        name: accountName,
        location: location,
        featureSet: featureSet,
        dataRetentionDays: dataRetention
      });

      setCreatedAccountId(response.accountId);
    } catch (error) {
      const errMsg = error?.response?.data?.message || "Oops, something went wrong. Please try again later.";
      setAlert(prev => ({
        show: true,
        type: "danger",
        message: errMsg,
        count: prev.count + 1,
      }));
    } finally {
      setProcessing(false);
    }
  };

  // Todo make sure we can't acess without beoing logged in
  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              {createdAccountId !== null ? (
                <>
                  <p>Account {createdAccountId} was created</p>
                  <ul>
                    <li>
                      <a
                        href={"/account/" + createdAccountId + "/users"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <HiOutlineExternalLink size={16} />
                        Manage Account Users
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "/account/" + createdAccountId + "/create-profile"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <HiOutlineExternalLink size={16} />
                        Create profile
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <TextField
                    handleChange={(e) => {
                      setAccountName(e.target.value);
                    }}
                    id="profile-name"
                    label="Account Name"
                    placeholder="e.g. Acme corporation"
                    text={accountName}
                  ></TextField>

                  <Field inline label="Data Location" columnwidths={[2, 4]}>
                    {loading ? (
                      <div className="form-control bg-light" style={{ height: '38px' }}>
                        <div className="spinner-border spinner-border-sm text-secondary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <select
                          value={location}
                          className="form-control"
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        >
                          {locations.map((loc) => (
                            <option key={loc.id} value={loc.id}>
                              {loc.name}
                            </option>
                          ))}
                        </select>
                        <small className="form-text text-muted">
                          Where data is processed and stored
                        </small>
                      </>
                    )}
                  </Field>

                  <Field inline label="Feature Set" columnwidths={[2, 10]}>
                    {loading ? (
                      <div className="row">
                        {[1, 2, 3].map((i) => (
                          <div key={i} className="col-md-4 mb-3">
                            <div className="card h-100 bg-light">
                              <div className="card-body">
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                  <div className="spinner-border text-secondary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row">
                        {featureSets.map((set) => (
                          <div key={set.id} className="col-md-4 mb-3">
                            <div 
                              className={`card h-100 ${featureSet === set.id ? 'border-primary bg-light' : ''}`}
                              onClick={() => setFeatureSet(set.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="card-body">
                                <h5 className="card-title">{set.name}</h5>
                                <div className="card-text">
                                  <div className="mb-2">
                                    <small className="text-muted">Session Replay Sampling:</small>
                                    <div>{set.SessionReplaySamplingRate}%</div>
                                  </div>
                                  <div className="mb-2">
                                    <small className="text-muted">Raw Datalayer Lookback:</small>
                                    <div>
                                      {set.datalayerLookbackDays === -1 
                                        ? 'Unlimited, same as data retention' 
                                        : set.datalayerLookbackDays <= 0 
                                          ? 'Not available'
                                          : `${set.datalayerLookbackDays} days`}
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <small className="text-muted">Content Loaded Lookback:</small>
                                    <div>
                                      {set.SessionReplaySamplingRate !== 100
                                        ? 'Sampled' 
                                        : "100%"
                                      }
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <small className="text-muted">API error tracking:</small>
                                    <div>
                                      {set.apiErrorAvailable === false 
                                        ? 'Not available' 
                                        : 'Unlimited endpoints'
                                      }
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <small className="text-muted">Max Profiles:</small>
                                    <div>
                                      {set.maxProfiles === -1 
                                        ? 'Unlimited' 
                                        : set.maxProfiles}
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <small className="text-muted">Mouse Hover event:</small>
                                    <div>
                                      {set.hoverEventSearcheable? "Yes" : "No" }
                                    </div>
                                  </div>
                                  <div>
                                    <small className="text-muted">Header upgrade icon:</small>
                                    <div>
                                      {set.showHeaderUpgrade? "Yes" : "No" }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Field>

                  <Field inline label="Data Retention" columnwidths={[2, 4]}>
                    {loading ? (
                      <div className="form-control bg-light" style={{ height: '38px' }}>
                        <div className="spinner-border spinner-border-sm text-secondary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <select
                        value={dataRetention}
                        className="form-control"
                        onChange={(e) => setDataRetention(Number(e.target.value))}
                        required
                      >
                        <option value={31}>1 month (31 days)</option>
                        <option value={93}>3 months (93 days)</option>
                        <option value={186}>6 months (186 days)</option>
                        <option value={365}>12 months (372 days)</option>
                      </select>
                    )}
                  </Field>

                  <SubmitButtons
                    processing={processing || loading}
                    backButtonTo="/"
                    handleClick={handleSumbit}
                  ></SubmitButtons>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import ReportsDropdownTable from "./ReportsDropdownTable";
import ReportsDropdownButton from "./ReportsDropdownButton";
import SearchBar from "../../share/SearchBar";
import { Button } from "../../share/InsightechUI";
import InsightechIcon from "../../share/InsightechIcon";
import { useHistory } from "react-router-dom";
import { UserDataContext } from "../UserData";
import { reportTypes } from "../../../helpers/reportTypes";

const ReportsDropdown = (props) => {
  const { reports, onReport, isDropdownVisible, setIsDropdownVisible, type } =
    props;
  const history = useHistory();
  const dropdownRef = useRef(null);
  const { activeProfileId } = useContext(UserDataContext);

  const [searchInputText, setSearchInputText] = useState("");

  const columns = [{ label: "Name" }, { label: "Range, Segments & Filters" }];

  const toggleReportsDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleKeywordChange = (e) => {
    const value = e.target.value;
    setSearchInputText(value);
  };

  const searchFilter = (report) => {
    return report.name?.toLowerCase().includes(searchInputText.toLowerCase());
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownVisible(false);
        setSearchInputText("");
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const handleViewAllReports = () => {
    history.push(`/profile/${activeProfileId}/report/list`);
    setIsDropdownVisible(false);
  };

  return (
    <div ref={dropdownRef} className="reports-dropdown-wrapper dropdown">
      <ReportsDropdownButton
        toggleReportsDropdown={toggleReportsDropdown}
        isVisible={isDropdownVisible}
      />
      <div
        className={`dropdown-menu dropdown-menu-md-right shadow animated--grow-in ${
          isDropdownVisible ? "show" : ""
        }`}
      >
        <div className="reports-dropdown-menu">
          <SearchBar
            searchInputText={searchInputText}
            handleKeywordChange={handleKeywordChange}
            placeholder={`Search ${reportTypes[type]} reports`}
          />
          <ReportsDropdownTable
            columns={columns}
            searchFilter={searchFilter}
            reports={reports}
            onReport={onReport}
          />
          <div className="reports-footer">
            <Button size="chip" color="plain" onClick={handleViewAllReports}>
              <InsightechIcon name="Reports" />
              View all saved reports
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ReportsDropdown.propTypes = {
  reports: PropTypes.array,
  onReport: PropTypes.func,
  isDropdownVisible: PropTypes.bool,
  setIsDropdownVisible: PropTypes.func,
  title: PropTypes.string,
  funnelId: PropTypes.string,
};

export default ReportsDropdown;

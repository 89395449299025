import moment from "moment";
import * as Sentry from "@sentry/browser";

export const isFunction = (functionName) => {
  return Object.prototype.toString.call(functionName) === "[object Function]";
};

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const arrayToReactSelectValue = (data) => {
  return data.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
};

export const splitEventConditions = (conditions) => {
  const eventConditions = conditions.filter((condition) => {
    return condition.inclusion;
  });
  const excludeEventConditions = conditions.filter((condition) => {
    return !condition.inclusion;
  });

  return [eventConditions, excludeEventConditions];
};

export const seggregatePageContainers = (pageContainers) => {
  const includedPageContainers = pageContainers.filter((container) => {
    if (container.hasOwnProperty("inclusion") && container.inclusion) {
      return true;
    }
    return false;
  });
  const excludedPageContainers = pageContainers.filter((container) => {
    if (container.hasOwnProperty("inclusion") && !container.inclusion) {
      return true;
    }
    return false;
  });

  return [includedPageContainers, excludedPageContainers];
};

export const capitalize = (text) => {
  let words = text.split(" ");
  let capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
};

export const overrideStyle = (node, style) => {
  Object.keys(style).forEach((prop) => {
    node.style[prop] = style[prop];
  });
};

export const ellipsize = (string, length = 150, suffix = "...") => {
  if (string.length <= length) {
    return string;
  }
  return `${string.substr(0, length)}${suffix}`;
};

export const randomPassword = () => {
  // 10 - 16 chars
  let pass = "";
  const len = Math.floor(Math.random() * (20 - 16 + 1) + 16);
  const alphaLow = "abcdefghijklmnopqrstuvwxyz";
  const alphaCap = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numeric = "0123456789";
  const special = "!@#$%^&*";
  const chars = [alphaLow, alphaCap, numeric, special];
  for (let i = 0; i < len; i++) {
    const charset = getRandomElementFromArray(chars);
    const char = getRandomElementFromArray(charset);
    pass += char;
  }

  // eslint-disable-next-line no-useless-escape
  let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/;

  if (!pattern.test(pass)) {
    return randomPassword();
  }

  return pass;
};

const getRandomElementFromArray = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const getUserPrefs = (profileId) => {
  let userPrefs = localStorage.getItem("userPrefs");
  try {
    userPrefs = JSON.parse(userPrefs);
  } catch {
    userPrefs = null;
  }
  if (userPrefs && userPrefs.profileId && userPrefs.profileId !== profileId) {
    return null;
  }
  return userPrefs;
};

export const saveUserPrefs = (prefs, profileId) => {
  const cachedPrefs = getUserPrefs(profileId);
  const oldData = cachedPrefs ? cachedPrefs.data : {};
  const userPrefs = {
    profileId,
    data: {
      ...oldData,
      ...prefs,
    },
  };

  localStorage.setItem("userPrefs", JSON.stringify(userPrefs));
};

export const checkNestedProperty = (object, properties) => {
  let placeholder = object;

  for (let i = 0, len = properties.length; i < len; i++) {
    const prop = properties[i];
    if (placeholder[prop]) {
      placeholder = placeholder[prop];
    } else {
      return false;
    }
  }
  return true;
};

/**
 * @param string urlString
 * @returns string
 */
export const parseUrl = (urlString) => {
  try {
    return new URL(urlString);
  } catch (error) {
    return "";
  }
};

/**
 * @param string urlString
 * @returns string
 */
export const parseUrlWithNoProtocol = (urlString) => {
  // Add dummy protocol to allow parsing
  return parseUrl("http://" + urlString);
};

// Splits a full name into first and last name. If a middle name exists, it's included in the last name.
export const getFirstAndLastName = (fullName) => {
  const nameParts = fullName.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  return { firstName, lastName };
};

export const kSeparatorDecimal = (val, minDigits, maxDigits) => {
  if (typeof val === "number") {
    const options = {};
    if (minDigits !== undefined) {
      options.minimumFractionDigits = minDigits;
    }
    if (maxDigits !== undefined) {
      options.maximumFractionDigits = maxDigits;
    }
    return val.toLocaleString(navigator.language, options);
  }
  return val;
};

export const formatValueWithSymbol = (prefix, value, suffix) => {
  return `${prefix || ""}${value}${suffix || ""}`;
};

export const formatChannelValue = (value) =>
  value === "DIRECT" ? "(direct)" : value.replace(/\//g, " / ");

export const calculatePercentage = (original, compared) => {
  if (compared === 0) {
    return "--%";
  } else {
    return (
      kSeparatorDecimal(((original - compared) / compared) * 100, 0, 2) + "%"
    );
  }
};

export const calculateConversionRate = (conversions, sessions) =>
  sessions > 0 ? parseFloat(((conversions / sessions) * 100).toFixed(2)) : 0;

export const calculateConversionRateDifference = (a, b) => {
  if (b === 0) {
    return "--%";
  } else if (a - b === 0) {
    return `${kSeparatorDecimal(a - b, 0, 2)}%`;
  } else {
    return `${kSeparatorDecimal(a - b, 0, 2)}%`;
  }
};

/**
 * set data range label based on the difference
 * among today, startDate, endDate
 * @param {moment} today
 * @param {moment} startDate
 * @param {moment} endDate
 * @param {React.Dispatch} setSelectedDateRange
 * @param {Object} dateRanges
 */
export const setDateRangeLabel = (
  today,
  startDate,
  endDate,
  setSelectedDateRange,
  dateRanges
) => {
  if (today.diff(startDate.startOf("day"), "d") === 0)
    setSelectedDateRange(dateRanges["today"]);
  else if (
    today.diff(startDate.startOf("day"), "d") === 1 &&
    today.diff(endDate.startOf("day"), "d") === 1
  )
    setSelectedDateRange(dateRanges["yesterday"]);
  else if (
    today.diff(startDate.startOf("day"), "d") === 3 &&
    today.diff(endDate.startOf("day"), "d") === 1
  )
    setSelectedDateRange(dateRanges["last3days"]);
  else if (
    today.diff(startDate.startOf("day"), "d") === 7 &&
    today.diff(endDate.startOf("day"), "d") === 1
  )
    setSelectedDateRange(dateRanges["last7days"]);
  else if (
    today.diff(startDate.startOf("day"), "d") === 30 &&
    today.diff(endDate.startOf("day"), "d") === 1
  )
    setSelectedDateRange(dateRanges["last30days"]);
  else setSelectedDateRange(dateRanges["custom"]);
};

/**
 * Merge fitler with both include event conditions and exclude conditions in the same pageView
 * @param {pageContainers} filters
 * @param {{userConditions, pageContainers}} appliedFilters
 * @returns
 */
export const mergeFiltersInPageView = (filters, appliedFilters) => {
  const mergedFilters = [...filters];
  if (Object.keys(appliedFilters).length === 0) return mergedFilters;

  const includeIndex = mergedFilters.findIndex(
    (filter) => filter.inclusion === true
  );
  const excludeIndex = mergedFilters.findIndex(
    (filter) => filter.inclusion === false
  );

  appliedFilters.pageContainers.forEach((pageContainer, index) => {
    if (pageContainer.inclusion === true && includeIndex !== -1)
      mergedFilters[includeIndex].eventConditions = mergedFilters[
        includeIndex
      ].eventConditions.concat(pageContainer.eventConditions);
    else if (pageContainer.inclusion === true && includeIndex === -1) {
      const includeFilter = { inclusion: true, eventConditions: [] };
      includeFilter.eventConditions = includeFilter.eventConditions.concat(
        pageContainer.eventConditions
      );
      mergedFilters.push(includeFilter);
    }

    if (pageContainer.inclusion === false && excludeIndex !== -1)
      mergedFilters[excludeIndex].eventConditions = mergedFilters[
        excludeIndex
      ].eventConditions.concat(
        appliedFilters.pageContainers[index].eventConditions
      );
    else if (pageContainer.inclusion === false && excludeIndex === -1) {
      const excludeFilter = { inclusion: false, eventConditions: [] };
      excludeFilter.eventConditions = excludeFilter.eventConditions.concat(
        pageContainer.eventConditions
      );
      mergedFilters.push(excludeFilter);
    }
  });

  return mergedFilters;
};

/**
 * Merge fitler with both include event conditions and exclude conditions as separate pageView
 * @param {pageContainer} filters
 * @param {{userConditions, pageContainers}} appliedFilters
 */
export const mergeFiltersOutPageView = (filters, appliedFilters) => {
  const mergedFilters = [...filters];
  if (Object.keys(appliedFilters).length === 0) return mergedFilters;

  const uniqueMergedFilters = mergedFilters
    .concat(appliedFilters.pageContainers)
    .reduce((accumulator, currentObj) => {
      const existingObj = accumulator.find(
        (obj) => JSON.stringify(obj) === JSON.stringify(currentObj)
      );
      if (!existingObj) accumulator.push(currentObj);
      return accumulator;
    }, []);

  return uniqueMergedFilters;
};

/**
 * Merge fitler with user conditions
 * @param {userConditions} filters
 * @param {{userConditions, pageContainers}} appliedFilters
 */
export const mergeFiltersUserConditions = (filters, appliedFilters) => {
  const mergedFilters = [...filters];

  if (
    appliedFilters?.userConditions?.length > 0 &&
    appliedFilters?.userConditions[0]?.attribute !== ""
  ) {
    const uniqueMergedFilters = mergedFilters
      .concat(appliedFilters.userConditions)
      .reduce((accumulator, currentObj) => {
        const existingObj = accumulator.find(
          (obj) => JSON.stringify(obj) === JSON.stringify(currentObj)
        );
        if (!existingObj) accumulator.push(currentObj);
        return accumulator;
      }, []);

    return uniqueMergedFilters;
  } else {
    return mergedFilters;
  }
};

/**
 * Get filter count based on the type
 * @param {string} type
 * @param {Array} filter
 * @returns
 * @example
 * getFilterCount("user", userConditions) // 1
 * getFilterCount("event", includedPageContainers) // 1
 * getFilterCount("excludedEvent", excludedPageContainers) // 1
 */
export const getFilterCount = (type, filter) => {
  let data = [];
  let aggregated = [];
  let arrayFilter = null;
  let filtered = [];
  switch (type) {
    case "user":
      data = [...filter];
      arrayFilter = (i) => {
        if (i.attribute && i.operator && i.values) {
          return true;
        }
        return false;
      };
      break;
    case "event":
      aggregated = filter.reduce((acc, val) => {
        if (val && val.eventConditions) {
          acc.push(...val.eventConditions);
        }
        return acc;
      }, []);
      data = [...aggregated];
      arrayFilter = (i) => {
        if (i.type && i.conditions) {
          return true;
        }
        return false;
      };
      break;
    case "excludedEvent":
      aggregated = filter.reduce((acc, val) => {
        if (val && val.eventConditions) {
          acc.push(...val.eventConditions);
        }
        return acc;
      }, []);
      data = [...aggregated];
      arrayFilter = (i) => {
        if (i.type && i.conditions) {
          return true;
        }
        return false;
      };
      break;
    default:
      break;
  }
  filtered = data.filter(arrayFilter);
  return filtered.length;
};

/**
 * Get required pref from the profile
 * @param {string} profileId
 * @param {string} prefKey
 * @returns {string || null}
 */

export const getProfilePref = (profileId, prefKey) => {
  if (!profileId || !prefKey) {
    return null;
  }

  let profilesPrefs = JSON.parse(localStorage.getItem("profilesPrefs"));

  if (!profilesPrefs) {
    return null;
  }

  if (
    profilesPrefs.hasOwnProperty(profileId) &&
    profilesPrefs[profileId].hasOwnProperty(prefKey)
  ) {
    return profilesPrefs[profileId][prefKey];
  }

  return null;
};

/**
 * Save pref into profile
 * @param {string} profileId
 * @param {string} prefKey
 * @param {string} prefValue
 * @returns
 */

export const saveProfilePref = (profileId, prefKey, prefValue) => {
  if (!profileId || !prefKey || !prefValue) {
    return;
  }

  let profilesPrefs = JSON.parse(localStorage.getItem("profilesPrefs"));

  if (!profilesPrefs) {
    profilesPrefs = {};
  }

  if (!profilesPrefs.hasOwnProperty(profileId)) {
    profilesPrefs[profileId] = {};
  }

  profilesPrefs[profileId][prefKey] = prefValue;

  localStorage.setItem("profilesPrefs", JSON.stringify(profilesPrefs));
};

export const defaultSortOrder = (value) => {
  return (a, b) => {
    if (a[value] < b[value]) {
      return -1;
    } else if (a[value] > b[value]) {
      return 1;
    }
    return 0;
  };
};

export const pushUserSignInEvent = (user) => {
  window.dataLayer.push({
    event: "user-sign-in",
    uid: user?.uid,
  });
  window.dataLayer.push({
    event: "user_identified",
    user_id: user?.uid,
  });
};

export const pushNonProfileViewEvent = (user) => {
  window.dataLayer.push({
    event: "non-profile-view",
    uid: user?.uid,
    email: user?.email,
    firstname: user?.displayName?.split(" ")[0],
    lastname: user?.displayName?.split(" ")[1],
    signUpAt: moment(parseInt(user?.reloadUserInfo?.createdAt))
      .utcOffset("+11:00")
      .format("YYYY-MM-DDTHH:mm:ssZ"),
    lastAccessTime: moment(user?.reloadUserInfo?.lastRefreshAt)
      .utcOffset("+11:00")
      .format("YYYY-MM-DDTHH:mm:ssZ"),
  });
};

export const pushProfileViewEvent = (user, attributes) => {
  window.dataLayer.push({
    event: "profile-view",
    uid: user?.uid,
    email: user?.email,
    firstname: user?.displayName?.split(" ")[0],
    lastname: user?.displayName?.split(" ")[1],
    signUpAt: moment(parseInt(user?.reloadUserInfo?.createdAt))
      .utcOffset("+11:00")
      .format("YYYY-MM-DDTHH:mm:ssZ"),
    lastAccessTime: moment(user?.reloadUserInfo?.lastRefreshAt)
      .utcOffset("+11:00")
      .format("YYYY-MM-DDTHH:mm:ssZ"),
    ...attributes,
  });
};

/**
 * Captures an exception to Sentry.
 * @param {Error} err - The error to be captured.
 */
export function captureExceptionToSentry(err) {
  Sentry.captureException(err);
}

// Opens the plan upgrade page in a new tab
export const navigateToPlanUpgrade = () => {
  window.open("/plan-upgrade", "_blank");
};

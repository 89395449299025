import React from "react";
import "./style.scss";
import { kSeparatorDecimal } from "../../../../utils";

const ToggleButton = (props) => {
  const {
    variant,
    opt1Label,
    opt1Value,
    opt2Label,
    opt2Value,
    isActive,
    onChange,
  } = props;

  return (
    <div
      className={`toggle-button ${variant} ${isActive ? "active" : ""}`}
      onClick={onChange}
    >
      <div className={`button-option ${!isActive ? "active" : ""}`}>
        {opt1Label} {!!opt1Value && `(${kSeparatorDecimal(opt1Value)})`}
      </div>
      <div className={`button-option ${isActive ? "active" : ""}`}>
        {opt2Label} {!!opt2Value && `(${kSeparatorDecimal(opt2Value)})`}
      </div>
    </div>
  );
};

export default ToggleButton;
